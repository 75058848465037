"use strict";

$(window).on('load', function () {
    detectTouchDevice();
    sidebarNav();
    sectionItemMenu();
    videoLoad();
    productSlider();
    sliders();
    hint();
    scroll();
    popups();
    catalogTagRemove();
    headerSearch();
    collection();
    compare();
    messagesPopup();
    gallery();
    comments();
    mainMenu();
    city();
    anchorScroll();
    tenders();
    inputTypeFile();
    adminItems();
    select();
    datepicker();
    updateTarifRowHeight();
    ymaps.ready(maps);
    autosize($('textarea'));
});

function adminItems() {
    $(".js-popup__btn").on("click", function () {
        $($(this).attr("href")).find(".js-remove-admin-item").attr("data-id", $(this).closest(".admin-item").attr('id'));
    });
    $(document).on("click", ".js-remove-admin-item", function () {
        $("#" + $(this).attr("data-id")).remove();
        $.magnificPopup.close();
    });
    $(".js-add-section-selects").on("click", function() {
        var fields = `
        <div class="js-sections-fields field__wrap--half wraped">
          <div class="field select select--solid">
            <select class="select__field" style="width:100%" data-class="solid" data-placeholder="Раздел">
              <option></option>
              <option>Основной раздел1</option>
              <option>Основной раздел2</option>
              <option>Основной раздел3</option>
              <option>Основной раздел4</option>
              <option>Основной раздел5</option>
              <option>Основной раздел6</option>
              <option>Основной раздел7</option>
              <option>Основной раздел8</option>
            </select>
          </div>
          <div class="field select select--solid">
            <select class="select__section" style="width:100%" data-placeholder="Подраздел">
              <option></option>
              <optgroup label="Облицовка">
                <option section-lvl="2">Алюминиевые композитные панели</option>
                <option section-lvl="2">Алюминиевые композитные панели</option>
                <option section-lvl="2">Керамогранит</option>
                <option section-lvl="3">Тонкий керамогранит</option>
                <option section-lvl="3">Обычный керамогранит</option>
              </optgroup>
              <optgroup label="ПОДСИСТЕМЫ">
                <option section-lvl="2">Алюминиевые</option>
                <option section-lvl="2">Оцинкованные</option>
                <option section-lvl="2">Стальные</option>
              </optgroup>
              <optgroup label="ИЗОЛЯЦИЯ">
                <option section-lvl="2">Минеральная изоляция</option>
                <option section-lvl="3">Каменная вата</option>
                <option section-lvl="3">Стекловата</option>
                <option section-lvl="2">Полимерная изоляция</option>
              </optgroup>
              <optgroup label="Облицовка">
                <option section-lvl="2">Алюминиевые композитные панели</option>
                <option section-lvl="2">Алюминиевые композитные панели</option>
                <option section-lvl="2">Керамогранит</option>
                <option section-lvl="3">Тонкий керамогранит</option>
                <option section-lvl="3">Обычный керамогранит</option>
              </optgroup>
              <optgroup label="ПОДСИСТЕМЫ">
                <option section-lvl="2">Алюминиевые</option>
                <option section-lvl="2">Оцинкованные</option>
                <option section-lvl="2">Стальные</option>
              </optgroup>
              <optgroup label="ИЗОЛЯЦИЯ">
                <option section-lvl="2">Минеральная изоляция</option>
                <option section-lvl="3">Каменная вата</option>
                <option section-lvl="3">Стекловата</option>
                <option section-lvl="2">Полимерная изоляция</option>
              </optgroup>
            </select>
          </div>
        </div>
        `;
        $(this).closest(".add-section__btn-wrap").prev(".js-sections-fields-wrap").append(fields);
        select();
    });
}

function updateTarifRowHeight() {
    var tarifRowHeight = 0;
    $(".view-tarif__row--head").each((index, item) => {
        if ($(item).outerHeight() > tarifRowHeight) {
            tarifRowHeight = $(item).outerHeight();
        }
    });
    $(".view-tarif__row--head").each((index, item) => {
        $(item).css("height", tarifRowHeight + "px");
    });
}

function datepicker() {
    $(".js-date").datepicker({
        autoClose: true,
    });
    $(".js-date-range").datepicker({
        autoClose: true,
        range: true,
        multipleDatesSeparator: " - "
    });
}

function inputTypeFile() {
    $(document).on("change", ".addmaterial__area input[type='file']", function () {
        var file = $(this)[0].files[0].name;
        if ($(this).val() != "") {
            $(this).closest(".addmaterial__area").find(".addmaterial__area__content").addClass("hidden");
            $(this).closest(".addmaterial__area").find(".addmaterial__area__loaded").addClass("active");
            $(this).closest(".addmaterial__area").find(".addmaterial__area__loaded").text(file);
            $(this).closest(".addmaterial__area").prev(".addmaterial__remove").addClass("active");
        }
    });
    $(document).on("click", ".js-addmaterial-remove", function () {
        $(this).next(".addmaterial__area").find("input[type='file']").val("");
        $(this).next(".addmaterial__area").find(".addmaterial__area__content").removeClass("hidden");
        $(this).next(".addmaterial__area").find(".addmaterial__area__loaded").removeClass("active");
        $(this).removeClass("active");
    });
}

function tenders() {
    $(".js-accordion-toggle").on("click", function () {
        $(this).toggleClass("active");
        $(this).next(".js-accordion-content").slideToggle(250);
    });
    $(".tenders-hero__bg").css({ "width": ($(window).innerWidth() - $(".tenders-hero__container").innerWidth()) / 2 + $(".tenders-hero__container").innerWidth() - $(".tenders-hero__content").innerWidth() + 100 + "px", "margin-left": $(".tenders-hero__content").innerWidth() - 100 + "px" });
    $(window).on("resize", function () {
        $(".tenders-hero__bg").css({ "width": ($(window).innerWidth() - $(".tenders-hero__container").innerWidth()) / 2 + $(".tenders-hero__container").innerWidth() - $(".tenders-hero__content").innerWidth() + 100 + "px", "margin-left": $(".tenders-hero__content").innerWidth() - 100 + "px" });
    });

    $(".tabs-btn").on("click", function () {
        $(this).closest(".tabs").find(".tabs-item.active").removeClass("animated");
        $(this).closest(".tabs").find(".tabs-btn").removeClass("active");

        setTimeout(() => {
            $(this).closest(".tabs").find(".tabs-item.active").removeClass("active");
            $(this).addClass("active");
            $(this).closest(".tabs").find(".tabs-item").eq($(this).index()).addClass("active");
            setTimeout(() => {
                $(this).closest(".tabs").find(".tabs-item").eq($(this).index()).addClass("animated");
            }, 100);
        }, 250);

    });

    $(".tabs-item__btn").on("click", function () {
        $(this).closest(".tabs").find(".tabs-item.active").removeClass("animated");
        $(this).closest(".tabs").find(".tabs-btn").removeClass("active");
        setTimeout(() => {
            $(this).closest(".tabs").find(".tabs-item.active").removeClass("active");
            $(this).closest(".tabs").find(".tabs-btn").eq($(this).closest(".tabs-item").index() + 1).addClass("active");
            $(this).closest(".tabs").find(".tabs-item").eq($(this).closest(".tabs-item").index() + 1).addClass("active");
            setTimeout(() => {
                $(this).closest(".tabs").find(".tabs-item").eq($(this).closest(".tabs-item").index() + 1).addClass("animated");
            }, 100);
        }, 250);
    });

    $(".js-addfile-img").on("click", function () {
        var count = $(this).closest(".addfile__item").find(".addmaterial__box").length;
        var imageTenders = `
        <div class="addfile__images-remove js-remove-files small"><span>Удалить</span><i></i></div>
        <div class="addfile__images">
        <div class="addmaterial__box addmaterial__box--control">
        <div class="addmaterial__remove js-addmaterial-remove"><i class="svg-image-cancel-collect svg-image-cancel-collect-dims"></i></div> 
          <label class="addmaterial__area" for="tenderPhoto`+ (count + 1) + `">
            <input type="file" id="tenderPhoto`+ (count + 1) + `"><span class="addmaterial__area__content"><i class="svg-image-add-image svg-image-add-image-dims"></i><span class="addmaterial__area__text medium">Загрузить фотографию <br><span class="gray">( jpg, png, gif, doc, до 1 Мб )</span></span></span><span class="addmaterial__area__loaded"></span>
          </label>
          <div class="field__wrap">
            <div class="field">       
              <input class="field__input" type="text" placeholder="Подпись под фото" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Подпись под фото'">
            </div>
          </div>
        </div>
        <div class="addmaterial__box addmaterial__box--control">
            <div class="addmaterial__remove js-addmaterial-remove"><i class="svg-image-cancel-collect svg-image-cancel-collect-dims"></i></div>
          <label class="addmaterial__area" for="tenderPhoto`+ (count + 2) + `">
            <input type="file" id="tenderPhoto`+ (count + 2) + `"><span class="addmaterial__area__content"><i class="svg-image-add-image svg-image-add-image-dims"></i><span class="addmaterial__area__text medium">Загрузить фотографию <br><span class="gray">( jpg, png, gif, doc, до 1 Мб )</span></span></span><span class="addmaterial__area__loaded"></span>
          </label>
          <div class="field__wrap">
            <div class="field">       
              <input class="field__input" type="text" placeholder="Подпись под фото" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Подпись под фото'">
            </div>
          </div>
        </div>
        </div>
        `;
        var imageViews = `
        <div class="addfile__images-remove js-remove-files small"><span>Удалить</span><i></i></div>
        <div class="addfile__images addfile__images--triple">
        <div class="addmaterial__box addmaterial__box--control">
        <div class="addmaterial__remove js-addmaterial-remove"><i class="svg-image-cancel-collect svg-image-cancel-collect-dims"></i></div> 
          <label class="addmaterial__area" for="tenderPhoto`+ (count + 1) + `">
            <input type="file" id="tenderPhoto`+ (count + 1) + `"><span class="addmaterial__area__content"><i class="svg-image-add-image svg-image-add-image-dims"></i><span class="addmaterial__area__text medium">Загрузить фотографию <br><span class="gray">( jpg, png, gif, doc, до 1 Мб )</span></span></span><span class="addmaterial__area__loaded"></span>
          </label>
        </div>
        <div class="addmaterial__box addmaterial__box--control">
            <div class="addmaterial__remove js-addmaterial-remove"><i class="svg-image-cancel-collect svg-image-cancel-collect-dims"></i></div>
          <label class="addmaterial__area" for="tenderPhoto`+ (count + 2) + `">
            <input type="file" id="tenderPhoto`+ (count + 2) + `"><span class="addmaterial__area__content"><i class="svg-image-add-image svg-image-add-image-dims"></i><span class="addmaterial__area__text medium">Загрузить фотографию <br><span class="gray">( jpg, png, gif, doc, до 1 Мб )</span></span></span><span class="addmaterial__area__loaded"></span>
          </label>
        </div>
        <div class="addmaterial__box addmaterial__box--control">
            <div class="addmaterial__remove js-addmaterial-remove"><i class="svg-image-cancel-collect svg-image-cancel-collect-dims"></i></div>
          <label class="addmaterial__area" for="tenderPhoto`+ (count + 3) + `">
            <input type="file" id="tenderPhoto`+ (count + 3) + `"><span class="addmaterial__area__content"><i class="svg-image-add-image svg-image-add-image-dims"></i><span class="addmaterial__area__text medium">Загрузить фотографию <br><span class="gray">( jpg, png, gif, doc, до 1 Мб )</span></span></span><span class="addmaterial__area__loaded"></span>
          </label>
        </div>
        </div>
        `;
        if ($(this).attr("data-addfile") == 2) {
            $(this).closest(".addfile__item").find(".addfile__images-wrap").append(imageTenders);
            if ($(this).closest(".addfile__item").find(".addmaterial__box").length == 6) {
                $(this).addClass("hidden");
            }
        }
        else if ($(this).attr("data-addfile") == 3) {
            $(this).closest(".addfile__item").find(".addfile__images-wrap").append(imageViews);
            if ($(this).closest(".addfile__item").find(".addmaterial__box").length == 9) {
                $(this).addClass("hidden");
            }
        }
    });

    $(document).on("click", ".js-remove-files", function () {
        $(this).next(".addfile__images").remove();
        $(this).remove();
        $(".js-addfile-img").removeClass("hidden");
    });

    // SUBMIT TENDER CREATE FORM
    $(".tenders-create__form").on("submit", function (e) {
        e.preventDefault();
        $(".tenders-create__during").fadeOut(400);
        setTimeout(function () {
            $(".tenders-create__success").fadeIn(350);
        }, 400);
    });
}

function anchorScroll() {
    $(document).on('click', 'a[href^="#"]', function (event) {
        if (!$(this).hasClass("js-popup__btn")) {
            if ($.attr(this, 'href').length > 1) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $($.attr(this, 'href')).offset().top - 40
                }, 800);
            }
        }
    });
}

function city() {
    // CHOOSE TENDER TYPE
    $('input[type=radio][name=cityTender]').change(function () {
        if (this.value == '1') {
            $(".city-choose__variant").eq(0).addClass("active");
            $(".city-choose__variant").eq(1).removeClass("active");
        }
        else if (this.value == '2') {
            $(".city-choose__variant").eq(1).addClass("active");
            $(".city-choose__variant").eq(0).removeClass("active");
        }
    });
    // ADD TO TENDER
    $(".js-add-city").on("click", function () {
        $(this).closest(".city-item__btn").addClass("added");
    });
    $(".js-remove-city").on("click", function () {
        $(this).closest(".city-item__btn").removeClass("added");
    });

    $(".city-choose__triangle").css({ "border-top": $(".city-choose").innerHeight() / 2 + "px solid transparent", "border-bottom": $(".city-choose").innerHeight() / 2 + "px solid transparent" });
    $(window).on("resize", function () {
        $(".city-choose__triangle").css({ "border-top": $(".city-choose").innerHeight() / 2 + "px solid transparent", "border-bottom": $(".city-choose").innerHeight() / 2 + "px solid transparent" });
    });
    $(".about-variant__content-triangle").css({ "border-left": $(".about-variant__content").innerWidth() / 2 + "px solid transparent", "border-right": $(".about-variant__content").innerWidth() / 2 + "px solid transparent" });
    $(window).on("resize", function () {
        $(".about-variant__content-triangle").css({ "border-left": $(".about-variant__content").innerWidth() / 2 + "px solid transparent", "border-right": $(".about-variant__content").innerWidth() / 2 + "px solid transparent" });
    });
}

function comments() {
    $(".object-comments__item-edit").on("click", function () {
        $(this).closest(".object-comments__item").addClass("edit");
        $(this).closest(".object-comments__item").find("textarea").attr("disabled", false);
    });
    $(".js-object-comment-edit").on("click", function () {
        $(this).closest(".object-comments__item").removeClass("edit");
        $(this).closest(".object-comments__item").find("textarea").attr("disabled", true);
    });
    $(".js-object-comment-delete").on("click", function () {
        $(this).closest(".object-comments__item").remove();
    });
    $(".js-object-comment-textarea").focus(function () {
        this.placeholder = '';
        $(this).next(".object-comments__textarea-nickname").addClass("active");
        $(this).css("padding-left", $(this).next(".object-comments__textarea-nickname").innerWidth() + 10 + "px");
    });
    $(".js-object-comment-textarea").blur(function () {
        if ($(this).val().length == 0) {
            this.placeholder = $(this).attr("data-placeholder");
            $(this).next(".object-comments__textarea-nickname").removeClass("active");
            $(this).css("padding-left", "20px");
        }
    });
}

function maps() {
    if ($("#object-map").length) {
        var objectMap = new ymaps.Map('object-map', {
            center: [55.76, 37.64],
            zoom: 10
        });
        var objectMarker = new ymaps.Placemark(objectMap.getCenter(), {
            // hintContent: 'Собственный значок метки',
            // balloonContent: 'Это красивая метка'
        }, {
                iconLayout: 'default#image',
                iconImageHref: 'images/map-marker.png',
                iconImageSize: [48, 48],
                iconImageOffset: [-5, -38]
            });
        objectMap.geoObjects.add(objectMarker);
    }
    if ($("#city-map").length) {
        var cityMapData = [
            {
                lat: 55.86,
                lng: 37.34,
                text: '3M Special Intertatment'
            },
            {
                lat: 55.56,
                lng: 37.54,
                text: 'KNAUF ITALIA'
            },
            {
                lat: 55.26,
                lng: 37.84,
                text: 'KNAUF'
            },
        ]
        var cityMap = new ymaps.Map('city-map', {
            center: [55.76, 37.64],
            zoom: 10
        });
        var myCollection = new ymaps.GeoObjectCollection();
        MyBalloonLayout = ymaps.templateLayoutFactory.createClass(
            '<div class="popover right">' +
            '<a class="close" href="#">&times;</a>' +
            '<div class="arrow"></div>' +
            '<div class="popover-inner">' +
            '$[[options.contentLayout observeSize]]' +
            '</div>' +
            '</div>', {
                build: function () {
                    this.constructor.superclass.build.call(this);

                    this._$element = $('.popover', this.getParentElement());

                    this.applyElementOffset();

                    this._$element.find('.close')
                        .on('click', $.proxy(this.onCloseClick, this));
                },
                clear: function () {
                    this._$element.find('.close')
                        .off('click');

                    this.constructor.superclass.clear.call(this);
                },
                onSublayoutSizeChange: function () {
                    MyBalloonLayout.superclass.onSublayoutSizeChange.apply(this, arguments);

                    if (!this._isElement(this._$element)) {
                        return;
                    }

                    this.applyElementOffset();

                    this.events.fire('shapechange');
                },
                applyElementOffset: function () {
                    this._$element.css({
                        top: -(this._$element[0].offsetHeight / 2)
                    });
                },
                onCloseClick: function (e) {
                    e.preventDefault();

                    this.events.fire('userclose');
                },
                getShape: function () {
                    if (!this._isElement(this._$element)) {
                        return MyBalloonLayout.superclass.getShape.call(this);
                    }

                    var position = this._$element.position();

                    return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
                        [position.left, position.top], [
                            position.left + this._$element[0].offsetWidth,
                            position.top + this._$element[0].offsetHeight + this._$element.find('.arrow')[0].offsetHeight
                        ]
                    ]));
                },
                _isElement: function (element) {
                    return element && element[0] && element.find('.arrow')[0];
                }
            }),
            MyBalloonContentLayout = ymaps.templateLayoutFactory.createClass(
                '<div class="popover-content">$[properties.balloonContent]</div>'
            );
        cityMapData.forEach(function (item, index) {
            window['cityMarker' + index] = new ymaps.Placemark([item.lat, item.lng], {
                balloonContent: item.text,
            }, {
                    balloonLayout: MyBalloonLayout,
                    balloonContentLayout: MyBalloonContentLayout,
                    iconLayout: 'default#image',
                    iconImageHref: 'images/map-marker2.png',
                    iconImageSize: [42, 73],
                    iconImageOffset: [-5, -38],
                    hideIconOnBalloonOpen: false
                });
            cityMap.geoObjects.add(window['cityMarker' + index]);
            myCollection.add(window['cityMarker' + index]);
            cityMap.geoObjects.add(myCollection);
        });
        cityMap.setBounds(myCollection.getBounds(), { checkZoomRange: true });


    }
}

function gallery() {
    $(document).ready(function () {
        var gallerySlider = $(".gallery-slider");
        gallerySlider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            var i = (currentSlide ? currentSlide : 0) + 1;
            $(".gallery-slider__counter").text(i + ' / ' + slick.slideCount);
            $(".gallery-slider__arrow").css("bottom", (gallerySlider.find(".slick-dots").outerHeight() + $(".gallery-slider__item.slick-current").find(".gallery-slider__item-content").outerHeight()) + "px");
        });
        gallerySlider.slick({
            dots: true,
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            fade: true,
            adaptiveHeight: true,
            prevArrow: "<div class='gallery-slider__arrow gallery-slider__arrow--prev'></div>",
            nextArrow: "<div class='gallery-slider__arrow gallery-slider__arrow--next'></div>",
        });
        $(".gallery-view__item").on("click", function () {
            $("html, body").addClass("overflow");
            $(this).closest(".gallery").find(".gallery-popup").addClass("active");
            gallerySlider.slick('setPosition');
            gallerySlider.slick('slickGoTo', $(this).index());
            $(".gallery-slider__item-img").each(function (index, item) {
                $(item).next(".gallery-slider__item-content").css("width", $(item).find("img").innerWidth() + "px");
            });
        });
        $(".js-gallery-close").on("click", function () {
            $("html, body").removeClass("overflow");
            $(this).closest(".gallery-popup").removeClass("active");
        });
    });
}

function messagesPopup() {
    $(".js-messages-open").on("click", function () {
        $(".messages__popup").stop().fadeIn(250);
    });
    $(".js-messages-popup-close").on("click", function () {
        $(".messages__popup").stop().fadeOut(250);
    });
    $(".messages__popup").draggable({ handle: ".messages__header", containment: "window", scrollSensitivity: 100, scrollSpeed: 100 });
}

// COMPARE SCROLL AND DELETE ITEMS
function compare() {
    // REMOVE COMPARISON
    $(".js-comparison-list-remove").on("click", function () {
        $($(this).attr("href")).find(".js-remove-list-compare").attr("data-id", $(this).closest(".comparison-list__item").attr('id'));
    });
    $(document).on("click", ".js-remove-list-compare", function () {
        $("#" + $(this).attr("data-id")).remove();
        $.magnificPopup.close();
    });
    $(".js-compare-scroll").mCustomScrollbar({
        axis: 'x',
        scrollbarPosition: 'inside',
        scrollButtons: {
            enable: true
        },
        advanced: {
            updateOnContentResize: true,
            // extraDraggableSelectors: ".comparison-features",
            // releaseDraggableSelectors: ".comparison-features"
        },
        live: true
    });
    updateCompareItemsHeight();
}

function updateCompareItemsHeight() {
    var propsCount = $(".comparison-item__prop").length / $(".comparison-item").length;
    var width = 0;
    for (var i = 0; i < propsCount; i++) {
        window["propHeight" + i] = 0
        for (var j = 0; j < $(".comparison-item").length; j++) {
            if ($(".comparison-item").eq(j).find(".comparison-item__prop").eq(i).find(".comparison-item__prop__text").outerHeight() > window["propHeight" + i]) {
                window["propHeight" + i] = $(".comparison-item").eq(j).find(".comparison-item__prop").eq(i).find(".comparison-item__prop__text").outerHeight();
            }
        }
        $(".comparison-item").each((index, item) => {
            $(item).find(".comparison-item__prop").eq(i).find(".comparison-item__prop__text").css("height", window["propHeight" + i] + "px");
        });
        $(".comparison-feature__item").eq(i).find(".comparison-feature__item__block").css("height", window["propHeight" + i] + "px");
    }
    $(".comparison-item").each((index, item) => {
        width += $(item).innerWidth();
    });
    $(".comparison-features").css("width", width + "px");

}

// COLLECTION ITEM FUNCTIONS
function collection() {
    // COPY COLLECTION LINK
    var clipboard = new ClipboardJS('.js-copy-btn');
    clipboard.on('success', function (e) {
        e.clearSelection();
    });
    $(".js-copy-btn").on("click", function () {
        if (!$(this).hasClass("tooltiped")) {
            $(this).addClass("tooltiped");
            setTimeout(() => { $(this).removeClass("tooltiped"); }, 1000);
        }
    });
    // REMOVE COLLECTION
    $(".js-popup__btn").on("click", function () {
        $($(this).attr("href")).find(".js-remove-collection").attr("data-id", $(this).closest(".collection").attr('id'));
    });
    $(document).on("click", ".js-remove-collection", function () {
        $("#" + $(this).attr("data-id")).remove();
        $.magnificPopup.close();
    });
    // OPEN COLLECTION EDIT
    $(".js-collection-edit").on("click", function () {
        $(this).closest(".collection").stop().addClass("active");
        $(this).closest(".collection").find(".collection-edit").stop().slideDown(250);
    });
    // CLOSE COLLECTION EDIT
    $(".js-collection-edit-close").on("click", function () {
        $(this).closest(".collection").stop().removeClass("active");
        $(this).closest(".collection-edit").stop().slideUp(250);
    });
    $(".js-collection-change").on("click", function () {
        if ($(this).prev(".field__input").val().length > 0) {
            $(this).closest(".collection").find(".collection-item__title").text($(this).prev(".field__input").val());
            $(".js-collection-edit-close").trigger("click");
            $(this).prev(".field__input").removeClass("error");
        }
        else {
            $(this).prev(".field__input").addClass("error");
        }
    });
}

// HEADER SEARCH AUTOCOMPLETE
function headerSearch() {

    $('.js-search').focus(function () {
        $(this).closest(".search-form").find(".placeholder").css("display", "none");
    })
    $('.js-search').blur(function () {
        if ($(this).val() == '') {
            $(this).closest(".search-form").find(".placeholder").css("display", "block");
        }
    })
    $(".js-search-close").on("click", function () {
        $('.js-search').val('').blur();
    });
    $('.js-search').easyAutocomplete({
        data: [
            {
                category: true,
                categoryShow: true,
                count: 1567,
                name: 'материалы',
                link: '#',
            },
            {
                category: false,
                name: 'Фасадный кронштейн РЛОРЛОР ываываыв',
                link: '#'
            },
            {
                category: false,
                name: 'Кронштейн фасадный левый ывфывф',
                link: '#'
            },
            {
                category: false,
                name: 'Фасадная плита ываываыва ываыва',
                link: '#'
            },
            {
                category: true,
                categoryShow: true,
                count: 67,
                name: 'компании и марки',
                link: '#',
            },
            {
                category: false,
                name: 'Фасадный кронштейн РЛОРЛОР',
                link: '#'
            },
            {
                category: false,
                name: 'Кронштейн фасадный левый',
                link: '#'
            },
            {
                category: false,
                name: 'Фасадная плита',
                link: '#'
            },
            {
                category: true,
                categoryShow: false,
                count: 67,
                name: 'статьи и новости',
                link: '#',
            },
            {
                category: true,
                categoryShow: false,
                count: 677,
                name: 'объекты',
                link: '#',
            },

        ],
        list: {
            maxNumberOfElements: 10,
            match: {
                // enabled: true
            },
            onShowListEvent: function () {
                $(".search-form__btn").addClass("active");
                $(".js-search-close").addClass("active");
            },
            onHideListEvent: function () {
                $(".search-form__btn").removeClass("active");
                $(".js-search-close").removeClass("active");
            }

        },
        getValue: "name",
        template: {
            type: "custom",
            method: function (value, item) {
                if (item.category) {
                    if (item.categoryShow) {
                        return "<a href=" + item.link + " class='eac-item__title'>" + "“<b>" + $('.js-search').val() + "</b>” в каталоге '" + value + "' (" + item.count + ")</a>";
                    }
                    else {
                        return "<a href=" + item.link + " class='eac-item__title'>В '" + value + "' (" + item.count + ")</a>";
                    }
                }
                else {
                    return "<a href=" + item.link + " class='eac-item__link'>" + value + "</a>";
                }
            }
        }
    });
}

// CATALOG TAGS REMOVE
function catalogTagRemove() {
    $('.js-catalog-tag-ramove').on('click', function () {
        $(this).closest('.catalog__tag').remove();
    });
    $('.js-catalog-tag-ramove-all').on('click', function () {
        $(this).closest('.catalog__tags').find(".catalog__tag").remove();
    });
}

// POPUP FUNCTIONS
function popups() {
    $('.js-popup-image').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
    });
    $('.js-popup__btn').magnificPopup({
        showCloseBtn: false,
        callbacks: {
            open: function () {
                if ($($.magnificPopup.instance.currItem.el[0]).data("checkbox")) {
                    var popup = $($.magnificPopup.instance.currItem.el[0]).attr("href");
                    $(popup).find("input[type=checkbox]").prop("checked", false);
                    $(popup).find("#" + $($.magnificPopup.instance.currItem.el[0]).data("checkbox")).prop("checked", true);
                }
            }
        }
    });
    $('.js-popup-close').on('click', function (e) {
        e.preventDefault();
        $.magnificPopup.close();
    });
    // REMOVE CATALOG POPUP CHECKBOXES
    $('.js-catalog-remove-checkboxes').on('click', function (e) {
        $(this).closest('.popup-catalog__item').find('input[type=checkbox]').prop('checked', false);
    });
}

// CUSTOM SCROLL FUNCTIONS
function scroll() {
    $('.js-scroll').mCustomScrollbar('destroy');
    $('.js-scroll').mCustomScrollbar('update');
    $('.js-scroll').mCustomScrollbar({
        axis: 'y',
        scrollbarPosition: 'inside',
        scrollButtons: {
            enable: true
        },
        advanced: {
            updateOnContentResize: true
        },
        live: true
    });
    $(".mCS_no_scrollbar").each(function () {
        $(this).prev(".personal-table__caption").addClass("no-scroll");
    });
}

function selectSection() {
    $(".select__section").select2({
        minimumResultsForSearch: -1,
        dropdownCssClass: 'select-drop solid section',
        language: "ru",
        dropdownAutoWidth: true,
        escapeMarkup: function (markup) {
            return markup;
        },
        templateResult: function(data) {
            if (!data.id) {
                return data.text;
            }
            var sectionLvl = $(data.element, this).attr("section-lvl");
            var $data = $('<div class="select-section__link--'+sectionLvl+'">'+data.text+'</div>');
            return $data;
        },
    })
}

// SELECT FUNCTIONS
function select() {
    function selectScrollBar() {
        $('.select2-results>.select2-results__options').mCustomScrollbar('destroy');
        $('.select2-results>.select2-results__options').mCustomScrollbar('update');

        setTimeout(function () {
            $('.select2-results>.select2-results__options').mCustomScrollbar({
                axis: 'y',
                scrollbarPosition: 'inside',
                scrollButtons: {
                    enable: true
                },
                advanced: {
                    updateOnContentResize: true
                },
                live: true
            });
        }, 0);

    }
    $('select').each((i, item) => {
        var className = '';
        if ($(item).attr('data-class')) {
            className = $(item).attr('data-class');
        }
        if ($(item).find('option').length < 10) {
            if ($(item).hasClass('select__field')) {
                $(item).select2({
                    minimumResultsForSearch: -1,
                    dropdownCssClass: 'select-drop ' + className,
                    language: "ru",
                    dropdownAutoWidth: true
                });
            }
            else if ($(item).hasClass('filter-select')) {
                $(item).select2({
                    minimumResultsForSearch: -1,
                    dropdownCssClass: 'filter-drop ' + className,
                    language: "ru",
                    dropdownAutoWidth: true
                });
            }
            else if ($(item).hasClass('tags__field')) {
                $('#tags').select2({
                    tags: true,
                    tokenSeparators: [','],
                    placeholder: "Add your tags here",
                    selectOnClose: true,
                    closeOnSelect: false,
                    dropdownAutoWidth: true
                });
            }
        }
        else {
            if ($(item).hasClass('select__field')) {
                $(item).select2({
                    dropdownCssClass: 'select-drop ' + className,
                    language: "ru",
                    dropdownAutoWidth: true
                });
            }
            else if ($(item).hasClass('filter-select')) {
                $(item).select2({
                    dropdownCssClass: 'filter-drop ' + className,
                    language: "ru",
                    dropdownAutoWidth: true
                });
            }
            else if ($(item).hasClass('tags__field')) {
                $(item).select2({
                    tags: true,
                    tokenSeparators: [','],
                    closeOnSelect: false,
                    dropdownCssClass: 'select-drop ' + className,
                    language: "ru",
                    dropdownAutoWidth: true
                });
            }
        }
    });
    selectSection();
    
    $('.filter-select').addClass('active');
    $("select").on("select2:unselect", function (evt) {
        if (!evt.params.originalEvent) {
            return;
        }

        evt.params.originalEvent.stopPropagation();
    });
    $('.filter-select').on('select2:close', function (e) {
        $(this).closest('.filter-item').find('.filter-select__head').removeClass('active');
    });
    $('.filter-select').on('select2:open', function (e) {
        $(this).closest('.filter-item').find('.filter-select__head').addClass('active');
    });
    $('select').on('select2:open', function (e) {
        $('.select2-search__field').on('keydown', function () {
            selectScrollBar();
        });
        selectScrollBar();
        setTimeout(function () {
            $(".select2-dropdown").css("width", $(".select2-dropdown").innerWidth() - 15 + "px");
        }, 0);
    });

}

// HINT FUNCTION
function hint() {
    $('.js-hint-open').on('click', function () {
        $('.hint__popup').stop().removeClass("active");
        $(this).prev('.hint__popup').stop().addClass("active");
    });
    $('.js-hint-close').on('click', function () {
        $(this).closest('.hint__popup').stop().removeClass("active");
    });
    // CAMPAIGN HINT
    $(".js-hint-campaign").on("click", function () {
        var item = $(this).closest(".personal-table__item"),
            popup = $(this).closest(".personal-table").find(".hint__campaign");
        $('.hint__popup').stop().removeClass("active");
        popup.stop().addClass("active");
        popup.css({ "top": item.offset().top + item.innerHeight() + 10 + "px", "left": item.offset().left + "px" });
        // PASS USER TO POPUP
        popup.find(".hint__campaign__user").text(item.attr("data-user"));

    });
}

// PRODUCT DETAIL SLIDER
function productSlider() {
    if ($('.js-product-big').length) {
        $('.js-product-big').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            asNavFor: '.js-product-small',
            fade: true,
        });
        $('.js-product-small').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: true,
            asNavFor: '.js-product-big',
            focusOnSelect: true,
            adaptiveHeight: true,
            prevArrow: "<div class='product-slider__arrow product-slider__arrow--prev'><i class='svg-image-arrow-slider svg-image-arrow-slider-dims'></i></div>",
            nextArrow: "<div class='product-slider__arrow product-slider__arrow--next'><i class='svg-image-arrow-slider svg-image-arrow-slider-dims'></i></div>",
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        vertical: false,
                        verticalSwiping: false
                    }
                },
                {
                    breakpoint: 601,
                    settings: {
                        vertical: false,
                        verticalSwiping: false,
                        slidesToShow: 2,
                    }
                },
            ]
        });
    }
}

// MAIN SLIDERS
function sliders() {
    if ($('.js-slider').length) {
        $('.js-slider').each(function (i, item) {
            var slick = $(this);
            var length = $(this).data('count');
            var responsive = [];
            if (length == 6) {
                responsive = [
                    {
                        breakpoint: 1350,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 5,
                        }
                    },
                    {
                        breakpoint: 1201,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 501,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 376,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            adaptiveHeight: true
                        }
                    },
                ]
            }
            else if (length == 5) {
                responsive = [
                    {
                        breakpoint: 1201,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 501,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 376,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            adaptiveHeight: true
                        }
                    },
                ]
            }
            else if (length == 4) {
                responsive = [
                    {
                        breakpoint: 1201,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            adaptiveHeight: true
                        }
                    },
                ]
            }
            else if (length == 3) {
                responsive = [
                    {
                        breakpoint: 1201,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 601,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            adaptiveHeight: true
                        }
                    },
                ]
            }
            else if (length == 2) {
                responsive = [
                    {
                        breakpoint: 601,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            adaptiveHeight: true
                        }
                    },
                ]
            }
            slick.slick({
                slidesToShow: length,
                slidesToScroll: length,
                infinite: false,
                outerEdgeLimit: true,
                prevArrow: "<div class='slider__arrow slider__arrow--prev'><i class='svg-image-arrow-slider svg-image-arrow-slider-dims'></i></div>",
                nextArrow: "<div class='slider__arrow slider__arrow--next'><i class='svg-image-arrow-slider svg-image-arrow-slider-dims'></i></div>",
                responsive: responsive
            });
        });
    }
    function sliderArrowsOffset() {
        $(".slider-default").each(function (i, item) {
            window["margin" + i] = 0;
            $(item).find(".slider-item__content").each(function (index, content) {
                if ($(content).innerHeight() > window["margin" + i]) {
                    window["margin" + i] = $(content).innerHeight();
                }
            });
            $(item).find(".slider__arrow").css("margin-bottom", window["margin" + i] + "px");
            $(item).find(".slider-item__content").css("height", window["margin" + i] + "px");
        });
    }
    sliderArrowsOffset();
    $(window).on("resize", function () {
        sliderArrowsOffset();
    });

}

// LOAD YOUTUBE IFRAME ON CLICK
function videoLoad() {

    $(".video-item__btn").on("click", function () {
        var frame = `
        <iframe 
            width="100%" 
            height="100%" 
            src="`+ $(this).attr('data-link') + `?autoplay=1" 
            frameborder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen></iframe>
        `;
        $(this).closest('.video-item__content').append(frame);
    });
}

// SECTION ITEM MENU TOGGLE
function sectionItemMenu() {
    if (window.touch) {
        $('.section-item__image').on('click', function () {
            $('.section-item__hover').stop().removeClass('active');
            $(this).next('.section-item__hover').stop().addClass('active');
        });
    }
}

// DETECT TOUCH DEVICE
function detectTouchDevice() {
    if ("ontouchstart" in document.documentElement) {
        $('body').addClass("touch");
        window.touch = true;
    }
    else {
        $('body').addClass("no-touch");
        window.touch = false;
    }
}

// MAIN MENU FUNCTION
function mainMenu() {
    let $container = $(".menu-more");
    let $primary = $container.find(".menu__list--primary");
    let $primaryItems = $container.find(".menu__list--primary > li:not(.menu__item--more)");

    $primary.append(`

      <li class="menu__item menu__item--more hidden">
        <div class="menu-more__btn">
          <span class="menu-more__btn__text medium">ЕЩё</span>
          <i class="svg-image-arrow-white-small svg-image-arrow-white-small-dims"></i>
        </div>
        <ul class="menu__list--secondary">
          ${$primary.html()}
        </ul>

      </li>

    `);

    let $secondary = $container.find('.menu__list--secondary');
    let $secondaryItems = $secondary.find('li');
    let $allItems = $container.find('li');
    let $moreLi = $('.menu__item--more');
    let $moreBtn = $('.menu-more__btn');

    function doAdapt() {
        $allItems.each(function () {
            $(this).removeClass('hidden');
        });

        let stopWidth = $moreBtn.outerWidth();
        let hiddenItems = [];
        let primaryWidth = $primary.outerWidth();
        $primaryItems.each(function (i) {
            if (primaryWidth >= (Number(stopWidth) + Number($(this).outerWidth()))) {
                stopWidth += $(this).outerWidth();
            } else {
                $(this).addClass('hidden');
                hiddenItems.push(i);
            }
        });

        if (!hiddenItems.length) {
            $moreLi.addClass('hidden');
        } else {
            $secondaryItems.each(function (i) {
                if (!hiddenItems.includes(i)) {
                    $(this).addClass('hidden');
                }
            })
        }
    }
    if (window.innerWidth > 991) {
        $moreLi.removeClass("disabled");
        doAdapt();
    }
    else {
        $moreLi.addClass("disabled");
        $allItems.each(function () {
            $(this).removeClass('hidden');
        });
    }
    $(window).on('resize', () => {
        if (window.innerWidth > 991) {
            $moreLi.removeClass("disabled");
            doAdapt();
        }
        else {
            $moreLi.addClass("disabled");
            $allItems.each(function () {
                $(this).removeClass('hidden');
            });
        }
    });

    $moreBtn.on('click', function (e) {
        if (window.touch) {
            e.preventDefault();
            $secondary.toggleClass('active');
            $moreBtn.toggleClass('active');
        }
    });
    $('.menu__item__icon').on('click', function () {
        $(this).stop().toggleClass('active');
        $(this).closest('.menu__item-toggle').next('.menu-drop').stop().slideToggle(250);
    });
    $('.header__burger').on('click', function () {
        $(this).stop().toggleClass('active');
        $('.header__catalog').stop().slideToggle(250);
    });

}

// SIDEBAR NAV ACCORDION
function sidebarNav() {
    $('.sidebar-nav__toggle').on('click', function () {
        $(this).closest('.sidebar-nav__item__wrap').stop().toggleClass('active');
        $(this).closest('.sidebar-nav__item__wrap').next('.sidebar-nav__sublist').stop().slideToggle(250);
    });
}